import React from 'react';

const Loading = ({show, define}) => {
    return (
        <div className={ !show ? 'fullpage-loader d-none' : 'fullpage-loader flex-column' }>
            <img src="./assets/images/loading.gif" height={50} className="d-block" alt=""/>
            {define?.temp?.failed_message && (
                <p className="text-danger">Sunucu ile bağlantı kurulamadı.</p>
            )}
        </div>
    );
};
export default Loading;
