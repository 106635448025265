import axiosInstance from "../helpers/axios";
import {generateUniqueId, guestToken, isLogin, user} from "../../helpers";

export const removeAddress = async (id) => {
    try {
        let url = '/customer/address/delete';
        const response = await axiosInstance.post(url, {id: id}); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default removeAddress;
