import axiosInstance from "../helpers/axios";
import {generateUniqueId, guestToken, isLogin, user} from "../../helpers";

export const addFavorite = async (product_id, remove=false) => {
    try {
        let url = '/customer/favorites/add';
        if(remove){
            url = '/customer/favorites/remove'
        }
        const response = await axiosInstance.post(url, {product_id: product_id}); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default addFavorite;
