import axiosInstance from "../helpers/axios";
import {generateUniqueId, guestToken, isLogin, user} from "../../helpers";

export const addCart = async (product_id, qty, product_variant_id) => {
    try {
        let url = '/customer/user-cart/add';
        if(!user()){
            url = '/customer/guest-cart/add';
        }
        const data = {
            product_id: product_id,
            product_variant_id: product_variant_id,
            qty: qty,
            guest_token: isLogin() ? false : guestToken()
        }
        const response = await axiosInstance.post(url, data); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default addCart;
