import React from 'react';
import {formatCurrency} from "../../helpers";

const FreeDelivery = ({free_delivery_limit, total, className}) => {
    return (
        <div className={`alert alert-mini alert-success ${className}`}>
            <b>{formatCurrency((free_delivery_limit - total))} TL</b>'lik
            daha ürün ekle, <b>teslimatın ücretsiz olsun!</b>
        </div>
    );
};
export default FreeDelivery;
