import axiosInstance from "../helpers/axios";

export const getProductDetail = async (lat, long, product_id) => {
    try {
        let url = '/customer/product-detail'; // API'den konumları almak için GET isteği
        const response = await axiosInstance.post(url, {latitude: lat, longitude: long, id: product_id, type: 'slug'}); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getProductDetail;
