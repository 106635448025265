import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {Breadcrumb, Button} from "react-bootstrap";
import getLocations from "../../redux/actions/getLocations";
import InputMask from "react-input-mask";
import {addAddress} from "../../redux/actions/addAddress";

const AddressModal = ({show, setShow, define, addItem, address}) => {
    const [newAddress, setNewAddress] = useState(!address)
    const [editLocation, setEditLocation] = useState(!address)
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedState, setSelectedState] = useState('')
    const [selectedArea, setSelectedArea] = useState('')
    const [selectedLat, setSelectedLat] = useState('')
    const [selectedLong, setSelectedLong] = useState('')
    const [formData, setFormData] = useState({
        id: false,
        name: '',
        mobile: '',
        type: 'Home',
        cities: [],
        city: '',
        city_id: '',
        districts: [],
        state: '',
        state_id: '',
        neighborhoods: [],
        area: '',
        area_id: '',
        latitude: '',
        longitude: '',
        address: ''
    });

    const handleItem = (name, e) => {
        setFormData({
            ...formData,
            [name]: e
        });
    };

    const handleSelect = (type, data) => {
        if (type === 'city') {
            setSelectedCity(data.title);
            handleItem('city_id', data.id);
        } else if (type === 'state') {
            setSelectedState(data.title);
            handleItem('state_id', data.id);
        } else if (type === 'area') {
            setSelectedArea(data.title);
            setSelectedLat(data.maxlatitude);
            setSelectedLong(data.maxlongitude);
        }
    }
    useEffect(() => {
        if (address && address.id) {
            setNewAddress(false);
            setEditLocation(false);
            setFormData({
                id: address.id,
                name: address && address?.id ? address.name : '',
                mobile: address && address?.id ? address.mobile : '',
                type: address && address?.id ? address.type : 'Home',
                cities: [],
                city: address && address?.id ? address.city : '',
                districts: [],
                state: address && address?.id ? address.state : '',
                neighborhoods: [],
                area: address && address?.id ? address.area : '',
                latitude: address && address?.id ? address.latitude : '',
                longitude: address && address?.id ? address.longitude : '',
                address: address && address?.id ? address.address : ''
            })
            setEditLocation(false)
        } else {
            setEditLocation(true)
            setFormData({
                id: false,
                name: '',
                mobile: '',
                type: 'Home',
                cities: [],
                city: '',
                districts: [],
                state: '',
                neighborhoods: [],
                area: '',
                latitude: '',
                longitude: '',
                address: ''
            })
            setNewAddress(true);
            setEditLocation(true);
        }
    }, [address]);
    useEffect(() => {

        console.log('test3')
        if (editLocation) {
            getLocations().then(r => {
                handleItem('cities', r.data)
            })
        } else {
            handleItem('cities', [])
        }
    }, [editLocation]);

    useEffect(() => {
        if (formData.city_id) {
            handleItem('districts', []);
            handleItem('neighborhoods', []);
            handleItem('state', null);
            handleItem('area', null);
            getLocations(formData.city_id).then(r => {
                handleItem('districts', r.data)
            })
        }
    }, [formData.city_id]);

    useEffect(() => {
        console.log('test5')
        if (formData.state_id) {
            handleItem('neighborhoods', []);
            handleItem('area', null);
            getLocations(formData.state_id).then(r => {
                handleItem('neighborhoods', r.data)
            })
        }
    }, [formData.state_id]);

    const handleClose = () => {
        setFormData({
            id: false,
            name: '',
            mobile: '',
            type: 'Home',
            cities: [],
            city: '',
            districts: [],
            state: '',
            neighborhoods: [],
            area: '',
            latitude: '',
            longitude: '',
            address: ''
        })
        setShow(false)
    }

    const handleSubmit = () => {
        const datas = {
            id: formData.id,
            name: formData.name,
            mobile: formData.mobile,
            type: formData.type,
            address: formData.address,
            latitude: selectedLat ?? formData.latitude,
            longitude: selectedLong ?? formData.latitude,
            city: selectedCity ?? formData.city,
            state: selectedState ?? formData.state,
            area: selectedArea ?? formData.area,
            country: 'Türkiye',
            landmark: 'Türkiye',
            pincode: 72500
        };
        console.log(datas)
        addAddress(datas).then(r => {
            if (r.message && r.message === "success") {
                setShow(false);
            }
        })
    }

    const handleEditClose = () => {
        setEditLocation(false);
        setSelectedCity('');
        setSelectedState('');
        setSelectedArea('');
        setSelectedLat('');
        setSelectedLong('');
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton={handleClose}>
                <Modal.Title className="f-22">{formData.id ? 'Adresi Düzenleyin' : 'Adres Ekleyin'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-4">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label htmlFor="" className="mb-2">Adı Soyadı</label>
                            <input type="text" className="form-control" value={formData.name}
                                   onChange={(e) => handleItem('name', e.target.value)}/>
                        </div>
                        <div className="form-group mt-3 ">
                            <label htmlFor="" className="mb-2">Telefon Numarası</label>
                            <InputMask mask="0\(599) 999 99 99" className="form-control"
                                       placeholder="0(5__) ___ __ __" value={formData.mobile}
                                       onChange={(number) => handleItem('mobile', number.target.value)}/>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <h5 className="mb-3">Adres tipi</h5>
                    </div>
                    <div className="col">
                        <button onClick={() => handleItem('type', 'Home')}
                                className={`btn w-100 ${formData.type === 'Home' ? ' btn-success' : ' btn-outline-secondary'}`}>
                            <i className="fa-duotone fa-house"></i> Ev
                        </button>
                    </div>
                    <div className="col">
                        <button onClick={() => handleItem('type', 'Business')}
                                className={`btn w-100 ${formData.type === 'Business' ? ' btn-success' : ' btn-outline-secondary'}`}>
                            <i className="fa-duotone fa-briefcase"></i> İş
                        </button>
                    </div>
                    <div className="col">
                        <button onClick={() => handleItem('type', 'Other')}
                                className={`btn w-100 ${formData.type === 'Other' ? ' btn-success' : ' btn-outline-secondary'}`}>
                            <i className="fa-duotone fa-bookmark"></i> Diğer
                        </button>
                    </div>
                </div>
                <div>
                    {editLocation && (
                        <div className="card">
                            {!newAddress && (
                                <button className="btn btn-danger btn-sm close-x" onClick={() => handleEditClose()}><i
                                    className="fa fa-times"></i></button>
                            )}
                            <div className="card-body">
                                <div className="form-group mb-3">
                                <label htmlFor="" className="mb-1">Şehir</label>
                                    <Select options={formData.cities && formData.cities.length ? formData.cities : []}
                                            placeholder="Seçim Yapılmadı"
                                            onChange={(city) => handleSelect('city', city)}/>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="" className="mb-1">İlçe</label>
                                    <Select options={formData.districts.length ? formData.districts : []}
                                            isDisabled={!formData.districts.length}
                                            placeholder="Seçim Yapılmadı"
                                            onChange={(district) => handleSelect('state', district)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="mb-1">Mahalle</label>
                                    <Select options={formData.neighborhoods.length ? formData.neighborhoods : []}
                                            isDisabled={!formData.neighborhoods.length}
                                            placeholder="Seçim Yapılmadı"
                                            onChange={(neighboor) => handleSelect('area', neighboor)}/>
                                </div>
                            </div>
                        </div>
                    )}
                    {formData.id && !editLocation && (
                        <Breadcrumb>
                            <Breadcrumb.Item active>{formData.city}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{formData.state}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{formData.area}</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => setEditLocation(!editLocation)}
                                             className="align-items-end">{editLocation ? 'Vazgeç' : 'Düzenle'}</Breadcrumb.Item>
                        </Breadcrumb>
                    )}
                    <div className="form-group mt-3">
                        <label htmlFor="" className="mb-1">Adres</label>
                        <textarea name="" value={formData.address}
                                  onChange={(e) => handleItem('address', e.target.value)} id=""
                                  className="form-control"></textarea>
                        <small className="text-muted f-10 lh-10">Adres bilgilerinizi kontrol edip düzeltme
                            yapabilir, <b>bina ismi,bina numarası, kat ve daire numarası</b> bilgilerinizi
                            ekleyebilirsiniz.</small>
                    </div>
                </div>
            </Modal.Body>
            <div className="modal-footer">
                <button onClick={() => handleSubmit()} className="btn bg-theme-color hover-bg-light"><i
                    className="fa fa-save"></i> Adresi Kaydet
                </button>
            </div>
        </Modal>
    );
};
export default AddressModal;
