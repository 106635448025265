import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Sidebar from "./partials/sidebar";
import getOrders from "../../redux/actions/getOrders";
import {Tab, Tabs, Accordion} from "react-bootstrap";
import {formatCurrency, formatDate, getOrderStatus, user} from "../../helpers";
import Pagination from "../../components/other/pagination";
import getFavorites from "../../redux/actions/getFavorites";
import ProductGrid from "../../components/boxes/product-grid";
import InputMask from "react-input-mask";

const Profile = ({define, addItem}) => {
    const [favorites, setFavorites] = useState(10);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        addItem('temp.loading', true);
        getFavorites(define.perm.latitude, define.perm.longitude).then(r => {
            if (r.data) {
                setFavorites(r.data);
            }
            addItem('temp.loading', false);
        })
    }, []);
    return (
        <>
            <section className="breadscrumb-section p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Profil Bilgileri</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'}>
                                                <i className="fa-solid fa-house"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Profil Bilgileri
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="user-dashboard-section section-b-space">
                <div className="container">
                    <div className="row">
                        <Sidebar define={define} addItem={addItem} active="profile"/>
                        <div className="col-lg-9 col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="mb-4">Profil Bilgileriniz</h4>
                                </div>
                                <div className="card-body">
                                    <div className="form-group mb-4">
                                        <label htmlFor="" className="mb-1">Adınız Soyadınız</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={user().name}
                                        />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="" className="mb-1">E-Posta Adresiniz</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={user().email}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="" className="mb-1">Telefon Numaranız</label>
                                        <InputMask mask="0\(599) 999 99 99" className="form-control"
                                                   placeholder="0(5__) ___ __ __"
                                                   value={user().mobile}
                                                   onChange={(number) => handleSetNumber(number)}/>
                                    </div>
                                    <button className="btn float-end bg-theme-color hover-bg-light btn-lg"
                                            onClick={() => handleSentOTP()}>
                                        <i className={'fa fa-save me-1'}></i>
                                        <b>Bilgileri Güncelle</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Profile;
