import React, {useEffect, useState} from 'react';
import addCart from "../../redux/actions/addCart";
import removeCart from "../../redux/actions/removeCart";
import Plus from "./types/plus";
import Button from "./types/button";
import {notify} from "../../helpers";

const AddCart = ({product, active, setActive, define, addItem, type = 'plus', cart = false, value = false}) => {
    const [cartValue, setCartValue] = useState(value ? value :  1);
    useEffect(() => {
        if (value) {
            setCartValue(value)
            setActive(true)
        }
    }, [value]);

    const handleActive = () => {
        addItem('temp.loading', true);
        addCart(cart ? product.product_id : product.id, cartValue, cart ? product.product_variant_id : product.variants[0].id).then(r => {
            if(!r.status){
                notify('Seçilen ürün için yeterli stok bulunmuyor');
                addItem('temp.loading', false);
            } else {
                notify('Ürün, sepetinize eklendi', 'success');
                setActive(true)
                addItem('temp.refresh_cart', true);
            }
        });
    }
    const handlePlus = () => {
        addItem('temp.loading', true);
        let new_value = cartValue + 1;
        addCart(cart ? product.product_id : product.id, new_value, cart ? product.product_variant_id : product.variants[0].id).then(r => {
            if(!r.status){
                notify('Seçilen ürün için yeterli stok bulunmuyor');
                addItem('temp.loading', false);
            } else {
                notify(product.name + ' için sepet adedi güncellendi', 'success');
                setCartValue(new_value)
                addItem('temp.refresh_cart', true);
                addItem('temp.loading', false);
            }
        });
    }
    const handleMinus = () => {
        addItem('temp.loading', true);
        if (cartValue > 1) {
            let new_value = cartValue - 1;
            addCart(cart ? product.product_id : product.id, new_value, cart ? product.product_variant_id : product.variants[0].id).then(r => {
                if(!r.status){
                    notify('Seçilen ürün için yeterli stok bulunmuyor');
                    addItem('temp.loading', false);
                } else {
                    notify(product.name + ' için sepet adedi güncellendi', 'success');
                    setCartValue(new_value)
                    addItem('temp.refresh_cart', true);
                    addItem('temp.loading', false);
                }
            });
        } else {
            removeCart(cart ? product.product_variant_id : product.variants[0].id).then(r => {
                setActive(false)
                addItem('temp.refresh_cart', true);
                addItem('temp.loading', false);
                notify(product.name + ', sepetinizden kaldırıldı', 'warning');
            });
        }
    }
    return (
        <>
            {type === 'plus' && (
                <Plus active={active} cartValue={cartValue} handleActive={handleActive} handlePlus={handlePlus}
                      handleMinus={handleMinus} cart={cart}/>
            )}
            {type === 'button' && (
                <Button active={active} cartValue={cartValue} handleActive={handleActive} handlePlus={handlePlus}
                        handleMinus={handleMinus} cart={cart}/>
            )}
        </>
    );
};

export default AddCart;
