import addCart from "./redux/actions/addCart";
import {getLogout} from "./utility/Utils";
import {useLocation} from "react-router-dom";
import {format, addDays, isAfter, setHours, setMinutes} from 'date-fns';
import trLocale from 'date-fns/locale/tr';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat, { masks } from "dateformat";

export const formatCurrency = (amount, currency = '') => {
    const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formattedAmount = formatter.format(amount);

    // Para birimi sembolü olmadan sadece sayı döndürülüyor
    return `${formattedAmount} ${currency}`;
};

export const formatDate = (dateString, format = 'DD.MM.YYYY') => {
    let date = new Date(dateString);
    function cevirTarih(tarih) {
        const ayCevir = {
            "January": "Ocak",
            "February": "Şubat",
            "March": "Mart",
            "April": "Nisan",
            "May": "Mayıs",
            "June": "Haziran",
            "July": "Temmuz",
            "August": "Ağustos",
            "September": "Eylül",
            "October": "Ekim",
            "November": "Kasım",
            "December": "Aralık"
        };

        // Regex kullanarak tarih içerisindeki ay ismini bulma
        const regex = new RegExp(Object.keys(ayCevir).join('|'), 'g');
        return tarih.replace(regex, match => ayCevir[match]);
    }
    date = dateFormat(date, format, false, true);
    date = cevirTarih(date);
    return date;
};

export const calculateDiscountPercentage = (originalPrice, newPrice) => {
    if (originalPrice <= newPrice) {
        return 0; // Yeni fiyat, orijinal fiyattan büyük veya eşitse indirim oranı sıfırdır
    }

    const discount = originalPrice - newPrice;
    const discountPercentage = Math.floor((discount / originalPrice) * 100);

    return discountPercentage; // İndirim oranını aşağıya yuvarlayarak döndürme
};

export const getAddCart = (product_id, qty, product_variant_id = 0) => {
    addCart(product_id, product_variant_id, qty).then(r => {
        return true;
    });
}

export const isLogin = () => {
    if (localStorage.getItem('user')) {
        return localStorage.getItem('user');
    }
    return false;
}

export const generateUniqueId = () => {
    const timeStamp = Date.now().toString(36); // Zaman damgasını 36 tabanına göre bir stringe dönüştürme
    const randomString = Math.random().toString(36).substring(2, 8); // Rastgele bir string üretme ve kesme

    return timeStamp + randomString; // Zaman damgası ile rastgele stringi birleştirme
};

export const guestToken = () => {
    if (!localStorage.getItem('guest_token')) {
        localStorage.setItem('guest_token', generateUniqueId());
    }
    return localStorage.getItem('guest_token');
}

export function slugify(str) {
    const turkishCharacters = {
        'ü': 'u', 'Ü': 'U', 'ş': 's', 'Ş': 'S', 'ı': 'i', 'İ': 'I',
        'ğ': 'g', 'Ğ': 'G', 'ö': 'o', 'Ö': 'O', 'ç': 'c', 'Ç': 'C',
        ' ': '-'
    };

    return str
        .replace(/./g, (c) => turkishCharacters[c] || c) // Türkçe karakterleri değiştirme
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\-]/g, '') // Özel karakterleri kaldırma
        .replace(/--+/g, '-') // Birden fazla tireyi tek tire ile değiştirme
        .replace(/^-+/, '') // Başındaki tireleri kaldırma
        .replace(/-+$/, ''); // Sondaki tireleri kaldırma
}

export function user() {
    if (localStorage.getItem('user') && localStorage.getItem('access_token')) {
        return JSON.parse(localStorage.getItem('user'));
    }
    return false;
}

export function getItemByID(arr, id = 1) {
    return arr.filter(function (eleman) {
        return eleman.id === id;
    })[0];
}

export function handleLogout() {
    getLogout().then(r => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        location.reload();
    });
}

export function getDaySlots(day = 7) {
    const today = new Date();
    const currentDate = new Date();

    // Saati 19:00'a ayarla
    const referenceTime = setHours(setMinutes(today, 0), 19);

    // Eğer şu anki saat 19:00'dan büyükse, bir sonraki güne geç
    if (isAfter(currentDate, referenceTime)) {
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const dates = [];

    for (let i = 0; i < day; i++) {
        const date = format(addDays(currentDate, i), 'yyyy-MM-dd');
        // const formattedDate = format(addDays(currentDate, i), 'd MMMM yyyy', {locale: trLocale});
        dates.push(date);
    }

    return dates;
}

export const checkTime = (selectedDate, from_time) => {
    const now = new Date();
    const fromDate = new Date(
        `${selectedDate}T${from_time}`
    );
    if (selectedDate > now.toISOString().split('T')[0]) {
        return true;
    } else if (
        selectedDate === now.toISOString().split('T')[0] &&
        now >= fromDate
    ) {
        return true;
    } else {
        return false;
    }
};

export const getArrayValues = (dataArray, key) => {
    return dataArray.map((item) => item[key]);
};

export function checkRequiredFields(array, requiredFields) {
    // Zorunlu alanları kontrol et
    for (const field of requiredFields) {
        // Eğer zorunlu alan bulunamazsa veya değeri false ise, key'i döndür
        if (!array.hasOwnProperty(field) || !array[field]) {
            return field;
        }
    }
    // Tüm zorunlu alanlar doldurulmuşsa false döndür
    return false;
}

export const notify = (message, type = "error") => {
    toast[type](message)
}

export const getOrderStatus = (status) => {
    let statuses = {
        1: 'Siparişiniz Alındı',
        2: 'Siparişiniz Onaylandı',
        3: 'Siparişiniz Hazırlanıyor',
        4: 'Siparişiniz Hazırlandı',
        5: 'Siparişiniz Dağıtıma Çıkarıldı',
        6: 'Siparişiniz Teslim Edildi',
        7: 'Siparişiniz İptal Edildi',
        8: 'Siparişiniz iade Edildi',
    }
    return statuses[status];
}

export const getShare = (url, title='', platform='facebook') => {
    if(platform === 'facebook'){
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    }  else if(platform === 'twitter'){
        return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
    } else if(platform === 'linkedin'){
        return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(title)}&source=${encodeURIComponent(url)}`;
    } else if(platform === 'whatsapp'){
        return `https://api.whatsapp.com/send?text=${encodeURIComponent(`${title}\n${url}`)}`;
    } else if (platform === 'telegram') {
        return `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
    } else {
        return url;
    }
}
