import axiosInstance from "../helpers/axios";
import {guestToken} from "../../helpers";
export const getLogin = async (phone_number, otp, register = false) => {
    try {
        let url = '/customer/login'; // API'den konumları almak için GET isteği
        const response = await axiosInstance.post(url, {mobile: phone_number, auth_uid: otp, guest_token: guestToken(), register: register}); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getLogin;
