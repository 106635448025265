import axiosInstance from "../helpers/axios";
import {generateUniqueId, guestToken, isLogin, user} from "../../helpers";

export const addAddress = async (data) => {
    try {
        let url;
        if(!data.id){
            url = '/customer/address/add';
        } else {
            url = '/customer/address/update';
        }
        const response = await axiosInstance.post(url, data); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default addAddress;
