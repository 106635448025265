import React, {useEffect, useState} from 'react';
import AddressModal from "../modals/address-modal";
import getAddresses from "../../redux/actions/getAddresses";
import removeAddress from "../../redux/actions/removeAddress";

const AddressesComponent = ({define, addItem, defaultAddress, setDefault, col=6, showModal=false}) => {
    const [address, setAddress] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [addressModalShow, setAddressModalShow] = useState(showModal);
    const [refreshAddresses, setRefreshAddresses] = useState(false);
    useEffect(() => {
        addItem('temp.loading', true);
        getAddresses().then(r => {
            addItem('temp.loading', false);
            setAddresses(r.data);
            setRefreshAddresses(false);
            if (r.data && r.data.length > 0) {
                if (r.data.find((item) => item.is_default === 1)) {
                    if(setDefault){
                        setDefault(r.data.find((item) => define.perm.address_id && define.perm.address_id === item.id))
                    }
                }
            }
        });
    }, [addressModalShow, refreshAddresses]);
    const handleEditAddress = (adres) => {
        setAddress(adres);
        setAddressModalShow(true)
    }

    const handleDeleteAddress = (id) => {
        removeAddress(id).then(r => {
            if(r.status){
                setRefreshAddresses(true)
            }
        })
    }
    return (
        <>
            <AddressModal show={addressModalShow} setShow={setAddressModalShow} addItem={addItem} define={define} address={address}/>
            {addresses && addresses.length > 0 ? (
                <div className="row">
                    {addresses.map((address) => (
                        <div className={`col-lg-${col}  col-md-${col} mb-4`}>
                            <div
                                className={`card text-secondary h-100${defaultAddress && defaultAddress.id === address.id ? ' active-address' : ' cursor-pointer passive-address'}`} onClick={() => setDefault ? setDefault(address) : false}>
                                <div className="card-header pe-0">
                                    <h6 className="pb-2 d-inline-block">{address.type === 'Home' ? 'Ev' : (address.type === 'Business' ? 'İş' : 'Diğer')} / {address.state}</h6>
                                    <div className="float-end d-inline-block mt--2">
                                        <button className="btn btn-link small text-warning" onClick={() => handleEditAddress(address)}><i className="fa fa-edit"></i></button>
                                        <button className="btn btn-link text-danger small" onClick={() => handleDeleteAddress(address.id)}><i className="fa fa-trash"></i></button>
                                    </div>
                                </div>
                                <div className="card-body border-success">
                                    <p className="h6">{address.name} ({address.mobile})</p>
                                    <p>{address.address}</p>
                                    <p className="mb-0">{address.state} / {address.area}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center">
                    <img src="./assets/images/no-address.png" height={150}
                         alt=""/>
                    <h6 className="mt-4 pt-2">
                        Sisteme tanımlı adresiniz bulunmuyor.
                    </h6>
                    <button onClick={() => setAddressModalShow(true)}
                            className="btn btn-outline-success"><i
                        className="fa fa-plus"></i> Adres Ekle
                    </button>
                </div>
            )}
            {addresses && addresses.length > 0 && (
                <>
                    <div
                        onClick={() => setAddressModalShow(true)}
                        className="btn bg-theme-light theme-color hover-bg-theme-color">
                        <i className="fa fa-plus"></i> Yeni bir adres
                        eklemek
                        istiyorum.
                    </div>
                </>
            )}
        </>
    );
};
export default AddressesComponent;
