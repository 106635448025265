import React from 'react';

const Button = ({active, cartValue, handleActive, handlePlus, handleMinus}) => {
    return (
        <div>
            <div className="add-to-cart-box add-to-cart-button">
                <button onClick={() => handleActive()}
                        className="btn btn-lg bg-theme-color hover-bg-light cart-button text-white">
                    <i className="fa-regular fa-cart-plus"></i> Sepete Ekle
                </button>
                <div className="cart_qty qty-box">
                    <div className="input-group">
                        <button type="button" className={`qty-left-minus ${cartValue === 1 ? 'bg-danger' : ''}`}
                                onClick={() => handleMinus()}
                                data-field="">
                            <i className={`fa ${cartValue === 1 ? 'fa-trash-alt' : 'fa-minus'}`}
                               aria-hidden="true"></i>
                        </button>
                        <input
                            className="form-control input-number qty-input"
                            type="text"
                            readOnly
                            name="quantity" value={cartValue}/>
                        <button type="button" className="qty-right-plus"
                                onClick={() => handlePlus()} data-field="">
                            <i className="fa fa-plus"
                               aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Button;
