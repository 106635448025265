import React, {useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import InputMask from "react-input-mask";
import Loading from "../../components/other/loading";
import sentOTP from "../../redux/actions/sentOTP";
import OtpInput from 'react-otp-input';
import getLogin from "../../redux/actions/getLogin";
import {user} from "../../helpers";
import {Link} from "react-router-dom";

const Login = ({addItem, define}) => {
    const [loginText, setLoginText] = useState('Giriş Yap');
    const [phoneNumber, setPhoneNumber] = useState('05');
    const [sentOtp, setSentOtp] = useState('05');
    const [enterOTP, setEnterOTP] = useState(false);
    const [otp, setOtp] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (user()) {
            location.href = '/';
        }
    }, []);
    useEffect(() => {
        addItem('temp.loading', true);
        setTimeout(() => {
            addItem('temp.loading', false);
        }, 2000)

    }, []);
    useEffect(() => {
        if (otp.length === 6) {
            setLoginText('Doğrulanıyor...')
            getLogin(phoneNumber, otp).then(r => {
                if (!r.status) {
                    setLoginText('Doğrulama Bekleniyor...');
                    setOtp('')
                    setError('Doğrulama kodunu hatalı girdiniz. Lütfen kontrol edin ve yeniden deneyin.');
                } else {
                    setLoginText('Yönlendiriliyor...')
                    localStorage.setItem('access_token', r.data.access_token);
                    localStorage.setItem('user', JSON.stringify(r.data.user));
                    location.reload();
                }

            })
        }
    }, [otp]);
    const handleClose = () => {
        setLoginModal(false)
    }

    const handleSetNumber = (number) => {
        let num = number.target.value;
        num = num.replace(/\D|^0+/g, '')
        setPhoneNumber(num)
    }

    const handleSentOTP = () => {
        setError(false);
        sentOTP(phoneNumber).then(r => {
            if (r.message === 'otp_sent') {
                setLoginText('Doğrulama Bekleniyor...')
                setEnterOTP(true);
            } else {
                setError('Telefon numarası, sistemdeki bilgilerle eşleşmedi. Lütfen kontrol edin ve yeniden deneyin.')
            }
        })
    }


    return (
        <section className="login-section">
            <div className="login-bg"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 offset-lg-3">
                        <Card>
                            <div className="card-header">
                                <h4 className="mb-3">Giriş Yap</h4>
                            </div>
                            <Card.Body>
                                {error && (
                                    <div className="alert alert-danger mx-3">{error}</div>
                                )}
                                <div className="p-4">
                                    {!enterOTP && (
                                        <>
                                            <p className=""><i className="fa fa-sms"></i> Cep Telefonu numaranızı girin
                                                ve doğrulayın.</p>
                                            <div className="form-group mb-3">
                                                <label htmlFor="" className="mb-1">Telefon Numaranız</label>
                                                <InputMask mask="0\(599) 999 99 99" className="form-control"
                                                           placeholder="0(5__) ___ __ __"
                                                           onChange={(number) => handleSetNumber(number)}/>
                                            </div>
                                        </>
                                    )}
                                    {enterOTP && (
                                        <div className="otp-inputs">
                                            <h6 className="mb-4">Lütfen telefonunuza gelen onay kodunu girin.</h6>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                            <div className="card-footer">
                                <a href="" className="me-auto text-muted d-inline-block mt-2"><small><i
                                    className="fa-regular fa-life-ring"></i> Giriş
                                    yapamıyorum!</small></a>
                                <button className="btn float-end bg-theme-color hover-bg-light btn-lg"
                                        disabled={phoneNumber.length === 10 && !enterOTP ? false : (enterOTP)}
                                        onClick={() => handleSentOTP()}>
                                    <i className={!enterOTP ? 'fa-regular fa-right-to-bracket' : 'fa-light fa-loader fa-spin'}></i>
                                    <b>{loginText}</b>
                                </button>
                            </div>
                            <div className="card-footer bg-theme-light text-center">
                                <div className="ms-auto me-auto">
                                    <p className="m-0">Henüz hesabınız yok mu?&nbsp;
                                        <Link to={'/kayit-ol'}  className="theme-color text-decoration-underline"><b>Üye Ol!</b></Link>
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Login;
