import React from 'react';
import Header from "../partials/header";
import Footer from "../partials/footer";
import Loading from "../components/other/loading";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/all.min.css';
import '../assets/css/style.css';
import '../assets/css/sahmar.css';
import '../assets/css/animate.min.css';
import {ToastContainer} from "react-toastify";

const Layout = ({children, addItem, define}) => {
    return (
        <div>
            <Loading show={define.temp.loading} />
            <Header addItem={addItem} define={define}/>
            <main>
                {children}
            </main>
            <Footer addItem={addItem} define={define}/>
            <ToastContainer />
        </div>
    );
};

export default Layout;
