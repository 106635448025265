import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Sidebar from "./partials/sidebar";
import getOrders from "../../redux/actions/getOrders";
import {Tab, Tabs, Accordion} from "react-bootstrap";
import {formatCurrency, formatDate, getOrderStatus} from "../../helpers";
import Pagination from "../../components/other/pagination";
import ImageWithFallback from "../../components/boxes/ImageWithFallback";

const Orders = ({define, addItem}) => {
    const [perPage, setPerPage] = useState(10);
    const [active_orders, setActiveOrders] = useState([]);
    const [activeOrdersTotal, setActiveOrdersTotal] = useState(0);
    const [activeOrdersPage, setActiveOrdersPage] = useState(1);
    const [activeOrdersPageCount, setActiveOrdersPageCount] = useState(1);
    const [old_orders, setOldOrders] = useState([]);
    const [oldOrdersTotal, setOldOrdersTotal] = useState(0);
    const [oldOrdersPage, setOldOrdersPage] = useState(1);
    const [oldOrdersPageCount, setOldOrdersPageCount] = useState(1);

    useEffect(() => {
        addItem('temp.loading', true);
        getOrders({type: 1, limit: 10, offset: (perPage * (activeOrdersPage - 1))}).then(r => {
            if (r.data) {
                let page_count = Math.ceil(r.total / perPage);
                setActiveOrdersPageCount(Array.from({length: page_count}, (_, index) => index + 1));
                setActiveOrders(r.data);
                setActiveOrdersTotal(r.total);
            }
        })
        getOrders({type: 0, limit: perPage, offset: (perPage * (oldOrdersPage - 1))}).then(r => {
            if (r.data) {
                let page_count = Math.ceil(r.total / perPage);
                setOldOrdersPageCount(Array.from({length: page_count}, (_, index) => index + 1));
                setOldOrders(r.data);
                setOldOrdersTotal(r.total);
            }
            addItem('temp.loading', false);
        })
    }, [activeOrdersPage, oldOrdersPage]);

    return (
        <>
            <section className="breadscrumb-section p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Siparişler</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'}>
                                                <i className="fa-solid fa-house"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Siparişler</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="user-dashboard-section section-b-space">
                <div className="container">
                    <div className="row">
                        <Sidebar define={define} addItem={addItem} active="orders"/>
                        <div className="col-lg-9 col-md-9">
                            <Tabs
                                defaultActiveKey="active"
                                id="fill-tab-example"
                                className="mb-0 fill-tab"
                                fill
                            >
                                <Tab eventKey="active" title="Aktif Siparişler" className="card border-top-0 user-card">
                                    <div className="card-body">
                                        {active_orders && active_orders.length ? (
                                            <Accordion defaultActiveKey="0" id={"order-accord"}>
                                                {active_orders.map((order) => (
                                                    <Accordion.Item eventKey={order.id}>
                                                        <Accordion.Header>
                                                            <div className="order-header">
                                                                <div className="order-cart">
                                                                    <div className="img-items">
                                                                        {order.items && order.items && order.items.map((item, i) => (
                                                                            <>
                                                                                {i < (order.items.length === 3 ? 3 : 2) && (
                                                                                    <div className="img-item" title={item.product_name}>
                                                                                        <ImageWithFallback
                                                                                            src={item.image_url}
                                                                                            className="img-radius float-start"
                                                                                            fallbackSrc="assets/images/sahmar-product.png"
                                                                                            alt={item.name}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        ))}
                                                                        {order.items && order.items.length > (order.items.length === 3 ? 3 : 2) && (
                                                                            <div className="img-item img-count">
                                                                                +{(order.items.length - 2)}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="order-info">
                                                                    <span>Sipariş No : <b>{order.orders_id}</b></span>
                                                                    <span
                                                                        className="date">{formatDate(order.created_at, 'd mmmm yyyy HH:MM')}</span>
                                                                </div>
                                                                <div className="order-status">
                                                                <span><i
                                                                    className="fa-duotone fa-clock text-warning"></i> {getOrderStatus(order.active_status)}</span>
                                                                </div>
                                                                <div className="order-price">
                                                                    <span>{formatCurrency(order.final_total)} TL</span>
                                                                    <span>{order.payment_method === 'COD' ? 'Kapıda Ödeme' : 'Kartla Ödeme'}</span>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    <h5 className="border-bottom pb-2">Sipariş
                                                                        Listesi</h5>
                                                                    <ul className="summery-contain order-list-summary-contain">
                                                                        {order.items.map((item) => (
                                                                            <li>
                                                                                <ImageWithFallback
                                                                                    src={item.image_url}
                                                                                    className="img-fluid checkout-image img-thumbnail me-3"
                                                                                    fallbackSrc="assets/images/sahmar-product.png"
                                                                                    alt={item.name}
                                                                                />
                                                                                <h4>{item.product_name}<br/>
                                                                                    <small>{item.quantity}
                                                                                        &nbsp;{item.unit}</small>
                                                                                </h4>
                                                                                <h4 className="price">{formatCurrency(item.sub_total)} TL</h4>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="position-sticky top-0">
                                                                        <h5 className="border-bottom pb-2">Tutarlar</h5>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div className="flex-grow-1 fw-bold">Ürünler
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{formatCurrency(order.total)} TL
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div
                                                                                className="flex-grow-1 fw-bold">Teslimat
                                                                                Tutarı
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{order.delivery_charge > 0 ? formatCurrency(order.delivery_charge) + ' TL' : 'Ücretsiz'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div
                                                                                className="flex-grow-1 fw-bold">Poşet
                                                                                Tutarı
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{order.bag_price > 0 ? formatCurrency(order.bag_price) + ' TL' : 'Ücretsiz'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div className="flex-grow-1 fw-bold">Genel
                                                                                Toplam
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{formatCurrency(order.final_total)} TL
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <div className="text-center td-bg-white py-4">
                                                <img src="./assets/images/no-cart.png" height={200} alt=""/>
                                                <h5 className="mt-4 mb-4">Aktif siparişiniz bulunmamaktadır</h5>
                                            </div>
                                        )}
                                    </div>
                                    <Pagination currentPage={activeOrdersPage} pageCount={activeOrdersPageCount} setPage={setActiveOrdersPage}/>
                                </Tab>
                                <Tab eventKey="history" title="Geçmiş Siparişler"
                                     className="card border-top-0 user-card">
                                    <div className="card-body">
                                        {old_orders && old_orders.length ? (
                                            <Accordion defaultActiveKey="0" id={"order-accord"}>
                                                {old_orders.map((order) => (
                                                    <Accordion.Item eventKey={order.id}>
                                                        <Accordion.Header>
                                                            <div className="order-header">
                                                                <div className="order-cart">
                                                                    <div className="img-items">
                                                                        {order.items && order.items && order.items.map((item, i) => (
                                                                            <>
                                                                                {i < (order.items.length === 3 ? 3 : 2) && (
                                                                                    <div className="img-item"
                                                                                         title={item.product_name}>
                                                                                        <ImageWithFallback
                                                                                            src={item.image_url}
                                                                                            className="img-radius float-start"
                                                                                            fallbackSrc="assets/images/sahmar-product.png"
                                                                                            alt={item.name}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        ))}
                                                                        {order.items && order.items.length > (order.items.length === 3 ? 3 : 2) && (
                                                                            <div className="img-item img-count">
                                                                                +{(order.items.length - 2)}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="order-info">
                                                                    <span>Sipariş No : <b>{order.orders_id}</b></span>
                                                                    <span
                                                                        className="date">{formatDate(order.created_at, 'd mmmm yyyy HH:MM')}</span>
                                                                </div>
                                                                <div className="order-status">
                                                                <span><i
                                                                    className="fa-duotone fa-clock text-warning"></i> {getOrderStatus(order.active_status)}</span>
                                                                </div>
                                                                <div className="order-price">
                                                                    <span>{formatCurrency(order.final_total)} TL</span>
                                                                    <span>{order.payment_method === 'COD' ? 'Kapıda Ödeme' : 'Kartla Ödeme'}</span>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    <h5 className="border-bottom pb-2">Sipariş
                                                                        Listesi</h5>
                                                                    <ul className="summery-contain order-list-summary-contain">
                                                                        {order.items.map((item) => (
                                                                            <li>
                                                                                <ImageWithFallback
                                                                                    src={item.image_url}
                                                                                    className="img-fluid checkout-image img-thumbnail me-3"
                                                                                    fallbackSrc="assets/images/sahmar-product.png"
                                                                                    alt={item.name}
                                                                                />
                                                                                <h4>{item.product_name}<br/>
                                                                                    <small>{item.quantity}
                                                                                        &nbsp;{item.unit}</small>
                                                                                </h4>
                                                                                <h4 className="price">{formatCurrency(item.sub_total)} TL</h4>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="position-sticky top-0">
                                                                        <h5 className="border-bottom pb-2">Tutarlar</h5>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div className="flex-grow-1 fw-bold">Ürünler
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{formatCurrency(order.total)} TL
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div
                                                                                className="flex-grow-1 fw-bold">Teslimat
                                                                                Tutarı
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{order.delivery_charge > 0 ? formatCurrency(order.delivery_charge) + ' TL' : 'Ücretsiz'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex flex-row mb-2">
                                                                            <div className="flex-grow-1 fw-bold">Genel
                                                                                Toplam
                                                                            </div>
                                                                            <div
                                                                                className="flex-grow-1 text-end">{formatCurrency(order.final_total)} TL
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <div className="text-center td-bg-white py-4">
                                                <img src="./assets/images/no-cart.png" height={200} alt=""/>
                                                <h5 className="mt-4 mb-4">Geçmiş siparişiniz bulunmamaktadır</h5>
                                            </div>
                                        )}
                                    </div>
                                    <Pagination currentPage={oldOrdersPage} pageCount={oldOrdersPageCount} setPage={setOldOrdersPage}/>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Orders;
