export const isUserLoggedIn = async () => {
    return await getUserData()
}
export const getUserData = async () => {
    try {
        const rtr = await AsyncStorage.getItem('userData')
        const tt = JSON.parse(rtr)
        return tt
    } catch (error) {
        console.error('Login bilgileri alınırken hata oluştu:', error)
        return false
    }
}

export const getLogout = async () => {
    try {
        return await AsyncStorage.removeItem('userData')
    } catch (error) {
        console.error('Çıkış yapılırken bir hata oluştu', error)
        return false
    }
}

export const getApiEndpoint = async () => {
    try {
        return await AsyncStorage.getItem('API_ENDPOINT')
    } catch (error) {
        console.error('Server bilgisi alınamadı:', error)
        return false
    }
}
export const getmaterialToken = () => {
    return Date.now() + Math.floor(Math.random() * 9000)
}

export const getIDs = (array) => {
    return array.reduce((total, elm) => {
        if (elm.hasOwnProperty('id')) {
            return total + elm.id
        } else {
            return total
        }
    }, 0)
}

export const genToken = (id) => {
    return "qCx23ftg22qQw"
}

export const getRefreshToken = () => {
    const bugununTarihi = new Date()
    const gun = bugununTarihi.getDate()
    const ay = bugununTarihi.getMonth() + 1
    const yil = bugununTarihi.getFullYear()
    return (parseInt(gun) * parseInt(ay) * parseInt(yil) * 998000556)
}


export function currencyFormat(num) {
    return '₺ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function kmFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " km"
}

export function inArray(needle, haystack) {
    if (!haystack) {
        return false
    }
    var length = haystack.length
    for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) return true
    }
    return false
}

export function getDateFormat(date, showHours = true) {
    const originalDate = new Date(date)

    const day = originalDate.getDate()
    const month = originalDate.getMonth() + 1
    const year = originalDate.getFullYear()
    const hours = originalDate.getHours() < 10 ? "0" + originalDate.getHours() : originalDate.getHours()
    const minutes = originalDate.getMinutes() < 10 ? "0" + originalDate.getMinutes() : originalDate.getMinutes()
    if (!showHours) {
        return `${day}/${month}/${year}`
    }
    return `${day}/${month}/${year} ${hours}:${minutes}`
}
export function getDateFormatForDB(date, showHours = true) {
    const originalDate = new Date(date)

    const day = originalDate.getDate()
    const month = originalDate.getMonth() + 1
    const year = originalDate.getFullYear()
    const hours = originalDate.getHours() < 10 ? "0" + originalDate.getHours() : originalDate.getHours()
    const minutes = originalDate.getMinutes() < 10 ? "0" + originalDate.getMinutes() : originalDate.getMinutes()
    if (!showHours) {
        return `${year}-${month}-${day}`
    }
    return `${year}-${month}-${day} ${hours}:${minutes}:00`
}
export function findDifference(arr1, arr2) {
    if (!arr2) {
        return arr1;
    }
    const longerArray = arr1.length > arr2.length ? arr1 : arr2;
    const shorterArray = arr1.length > arr2.length ? arr2 : arr1;
    let arr = []
    for (let i = 0; i < longerArray.length; i++) {
        if(!inArray(longerArray[i].id, extractIDs(shorterArray))){
            arr.push(longerArray[i])
        }
    }

    return arr
}

export function extractIDs(data) {
    const ids = [];

    for (let i = 0; i < data.length; i++) {
        if (data[i].hasOwnProperty("id")) {
            ids.push(data[i].id)
        }
    }

    return ids
}
