import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {Button} from "react-bootstrap";
import getLocations from "../../redux/actions/getLocations";
import {user} from "../../helpers";
import AddressesComponent from "../other/addresses";

const LocationModal = ({show, setShow, define, addItem}) => {
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [orderAddress, setOrderAddress] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(null);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [neighborhood, setNeighborhood] = useState(null);

    const handleCity = (id) => {
        setCity(id)
        setDistrict(null)
        setDistricts([])
        setNeighborhood(null)
        setNeighborhoods([])
        getLocations(id.value).then(r => {
            setDistricts(r.data)
        })
    }
    const handleDistrict = (id) => {
        setDistrict(id)
        setNeighborhood(null)
        setNeighborhoods([])
        getLocations(id.value).then(r => {
            setNeighborhoods(r.data)
        })
    }
    const handleNeighborhood = (id) => {
        setNeighborhood(id)
        addItem('perm.latitude', id.maxlatitude)
        addItem('perm.longitude', id.maxlongitude)
        addItem('perm.is_settings', false)
        setShow(false)
    }
    useEffect(() => {
        getLocations().then(r => {
            setCities(r.data)
        })
    }, []);

    useEffect(() => {
        if (city) {
            addItem('perm.city', city.title)
            addItem('perm.city_id', city.id)
        }
        if (district) {
            addItem('perm.district', district.title)
            addItem('perm.district_id', district.id)
        }
        if (neighborhood) {
            addItem('perm.neighbor', neighborhood.title)
            addItem('perm.neighbor_id', neighborhood.id)
        }
    }, [neighborhood]);

    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        if(orderAddress.latitude){
            addItem('perm.address_id', orderAddress.id);
            addItem('perm.city', orderAddress.city);
            addItem('perm.district', orderAddress.state);
            addItem('perm.neighbor', orderAddress.area);
            addItem('perm.latitude', orderAddress.latitude);
            addItem('perm.longitude', orderAddress.longitude);
            addItem('temp.refresh_cart', true);
        }
    }, [orderAddress]);
    return (
        <Modal show={!!cities.length && show} onHide={handleClose}>
            <Modal.Header closeButton={handleClose}>
                <Modal.Title className="f-22">Teslimat Adresi Seçin</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                {!user() ? (
                    <div>
                        <p className="px-4">Şahmar kalitesini en üst düzeyde hissetmek için lütfen adresinizi seçin.</p>
                        <div className="p-4">
                            <div className="form-group mb-3">
                                <label htmlFor="" className="mb-1">Şehir</label>
                                <Select options={cities.length ? cities : []} placeholder="Seçim Yapılmadı"
                                        onChange={(city) => handleCity(city)}/>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="" className="mb-1">İlçe</label>
                                <Select options={districts.length ? districts : []} isDisabled={!city}
                                        placeholder="Seçim Yapılmadı"
                                        onChange={(district) => handleDistrict(district)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="mb-1">Mahalle</label>
                                <Select options={neighborhoods.length ? neighborhoods : []}
                                        isDisabled={!districts.length}
                                        placeholder="Seçim Yapılmadı"
                                        onChange={(neighboor) => handleNeighborhood(neighboor)}/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p className="px-4">Şahmar kalitesini en üst düzeyde hissetmek için lütfen adresinizi seçin.</p>
                        <AddressesComponent addItem={addItem} define={define}
                                            setDefault={setOrderAddress} col={12}
                                            defaultAddress={orderAddress}/>
                    </div>
                )}

            </Modal.Body>
        </Modal>
    );
};
export default LocationModal;
