import axiosInstance from "../helpers/axios";
import {guestToken, user} from "../../helpers";
export const getPlaceOrder = async (data) => {
    try {
        let url = '/customer/place_order';
        const response = await axiosInstance.post(url, data); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getPlaceOrder;
