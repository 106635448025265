import React, {useEffect, useState} from 'react';
import getCategories from "../../../redux/actions/getCategories";
import {Link} from "react-router-dom";
import {slugify} from "../../../helpers";
import ImageWithFallback from "../../../components/boxes/ImageWithFallback";

const Categories = ({addItem}) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    useEffect(() => {
        addItem('temp.loading', true)
        getCategories(null).then(r => {
            addItem('temp.loading', false)
            setCategories(r.data);
        });
    }, []);
    return (
        <>
            <ul className="category-list">
                {!categories || !categories.length && (
                    <li className="onhover-category-list not-found-category">Yükleniyor...</li>
                )}
                {categories && categories.length > 0 && categories.map((item, index) => (
                    <li className="onhover-category-list" onMouseEnter={() => setSelectedCategory(item)} key={index}>
                        <Link to={`/kategori/${slugify(item.name)}/${item.id}`} className="category-name">
                            <ImageWithFallback
                                src={item.image_url}
                                fallbackSrc="assets/images/sahmar-category.png"
                                alt={item.name}
                            />
                            <h6>{item.name}</h6>
                            <i className="fa-solid fa-angle-right"></i>
                        </Link>
                    </li>
                ))}
            </ul>
            {selectedCategory && selectedCategory.id && selectedCategory.cat_active_childs && selectedCategory.cat_active_childs.length > 0 && (
                <div className="sub-category-list" onMouseLeave={() => setSelectedCategory([])}>
                    <div className="row h-100">
                        <div className="col-lg-9 col-md-9 offset-lg-3 ps-0 position-relative h-100">
                            <div className="scats">
                                <div className="row">
                                    {selectedCategory && selectedCategory.id && selectedCategory.cat_active_childs && selectedCategory.cat_active_childs.length > 0 && selectedCategory.cat_active_childs.map((sub, index) => (
                                        <div className="col-lg-4 col-md-4" key={index}>
                                            <h6><Link className="theme-color"
                                                to={`/kategori/${slugify(sub.name)}/${sub.id}`}>{sub.name}</Link></h6>
                                            {sub.cat_active_childs && sub.cat_active_childs.length > 0 && (
                                                <ul>
                                                    {sub.cat_active_childs.map((child) => (
                                                        <li>
                                                            <Link
                                                                to={`/kategori/${slugify(child.name)}/${child.id}`}>{child.name}</Link>
                                                        </li>
                                                    ))}

                                                </ul>
                                            )}

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Categories;
