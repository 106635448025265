import {store} from "../stores";
import axios from 'axios';
import {getUserData} from "../../utility/Utils";

const axiosInstance = axios.create({
    baseURL: '',
    header: {
        Authorization: ''
    }
});
axiosInstance.interceptors.request.use(async (req) => {
    const token = localStorage.getItem('access_token')
    req.baseURL = process.env.REACT_APP_API_URL
    req.headers['X-Requested-With'] = 'XMLHttpRequest';
    req.headers.Authorization = token ? `Bearer ${token}` : false;
    return req;
});

export default axiosInstance;
