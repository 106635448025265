import React, {useEffect} from 'react';

const Pagination = ({pageCount, currentPage, setPage}) => {
    useEffect(() => {
        console.log(pageCount)
    }, []);
    return (
        <>
            {pageCount.length > 1 && (
                <nav className="custome-pagination">
                    <ul className="pagination justify-content-center">
                        {currentPage > 2 && (
                            <li className="page-item cursor-pointer" onClick={() => setPage(1)}>
                                <a className="page-link">
                                    <i className="fa-solid fa-angles-left"></i>
                                </a>
                            </li>
                        )}
                        {currentPage > 1 && (
                            <li className="page-item cursor-pointer" onClick={() => setPage(currentPage - 1)}>
                                <a className="page-link">
                                    <i className="fa-solid fa-angle-left"></i>
                                </a>
                            </li>
                        )}
                        {pageCount.length > 1 && pageCount.map((page, index) => (
                            <>
                                { index < (currentPage + 4) && index >= (currentPage-2) && (
                                    <li className={`page-item cursor-pointer ${page === currentPage ? 'active' : ''}`}
                                        onClick={() => setPage(page)}>
                                        <a className="page-link">{page}</a>
                                    </li>
                                )}
                                {index === (currentPage + 5) && index < (pageCount.length - 3) && (
                                    <li className={`page-item cursor-pointer`}>
                                        <a className="page-link">...</a>
                                    </li>
                                )}
                                { index > currentPage && index > (pageCount.length - 5) && index > (currentPage+3) && (
                                    <li className={`page-item cursor-pointer ${page === currentPage ? 'active' : ''}`}
                                        onClick={() => setPage(page)}>
                                        <a className="page-link">{page}</a>
                                    </li>
                                )}
                            </>
                        ))}
                        {currentPage < pageCount.length && (
                            <li className="page-item cursor-pointer" onClick={() => setPage(currentPage + 1)}>
                                <a className="page-link">
                                    <i className="fa-solid fa-angle-right"></i>
                                </a>
                            </li>
                        )}
                    </ul>
                </nav>
            )}
        </>
    );
};
export default Pagination;
