import React, {useEffect, useState} from 'react';
import {calculateDiscountPercentage, formatCurrency} from "../../helpers";
import addCart from "../../redux/actions/addCart";
import removeCart from "../../redux/actions/removeCart";
import AddCart from "../addCart";
import {Link} from "react-router-dom";
import ImageWithFallback from "./ImageWithFallback";

const ProductGrid = ({product, className, addItem}) => {
    const [active, setActive] = useState(false);
    return (
        <div className={`product-box product-box-bg wow fadeInUp position-relative ${active ? 'product-active' : ''} ${className}`}>
            <div className="product-image">
                <Link to={`/urun/${product.slug}`}>
                    <ImageWithFallback
                        src={product.image_url}
                        fallbackSrc="assets/images/sahmar-product.png"
                        className="img-fluid"
                        alt={product.name}
                    />
                </Link>
            </div>
            <div className="product-detail">
                <Link to={`/urun/${product.slug}`}>
                    <h6 className="name mb-0 pb-0">{product.name}</h6>
                    <p className="text-muted">
                        <small>{product.variants[0]?.unit ? product.variants[0]?.unit.name : product.variants[0]?.stock_unit_name}</small></p>
                </Link>
                {product.variants[0].discounted_price > 0 && (
                    <>
                        <div className="discount-price">
                            <small>%{calculateDiscountPercentage(product.variants[0].price, product.variants[0].discounted_price)} İNDİRİM</small>
                        </div>
                    </>
                )}
                <h5 className={`sold text-content d-inline-block ${product.variants[0].discounted_price ? 'discounted-product' : ''}`}>
                    {product.variants[0].discounted_price > 0 && (
                        <>
                            <del className="ms-0 f-10">
                                <small>{formatCurrency(product.variants[0].price)}
                                    TL</small></del>
                            <br/>
                        </>
                    )}
                    <span
                        className={`price ${!product.variants[0].discounted_price ? 'theme-color' : ''}`}>{formatCurrency(product.variants[0].discounted_price ? product.variants[0].discounted_price : product.variants[0].price)}
                        <small>TL</small></span>

                </h5>
                <AddCart product={product} active={active} setActive={setActive} addItem={addItem} />
            </div>
        </div>
    );
};

export default ProductGrid;
