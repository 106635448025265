import axiosInstance from "../helpers/axios";

export const getShopInformations = async (lat, long) => {
    try {
        let url = '/customer/shop?latitude='+lat+'&longitude='+long;
        const response = await axiosInstance.get(url); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getShopInformations;
