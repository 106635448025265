import React, {useEffect, useRef, useState} from "react";
import {Breadcrumb, Tab, Tabs} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import getProductDetail from "../../redux/actions/getProductDetail";
import {calculateDiscountPercentage, formatCurrency, getShare, notify, slugify, user} from "../../helpers";
import AddCart from "../../components/addCart";
import addFavorite from "../../redux/actions/addFavorite";
import ImageWithFallback from "../../components/boxes/ImageWithFallback";

const Detail = ({define, addItem}) => {
    const [active, setActive] = useState(false);
    const {urun_id} = useParams();
    const [product, setProduct] = useState([]);
    const [favorite, setFavorite] = useState(false);
    useEffect(() => {
        if (urun_id) {
            addItem('temp.loading', true);
            getProductDetail(define.perm.latitude, define.perm.longitude, urun_id).then(r => {
                setProduct(r.data);
                setFavorite(r.data.is_favorite);
                addItem('temp.loading', false);
            });
        }
    }, [urun_id, favorite]);

    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            const parser = new DOMParser();
            const parsedHTML = parser.parseFromString(product.description, 'text/html');
            contentRef.current.innerHTML = '';
            contentRef.current.appendChild(parsedHTML.body);
        }
    }, [product, favorite]);

    const handleFavorite = (id) => {
        if(!user()){
            notify('Lütfen giriş yapın ve yeniden deneyin.', 'error');
            return false;
        }
        addItem('temp.loading', true)
        if (!product.is_favorite) {
            addFavorite(id).then(r => {
                notify(product.name + ', beğendiğiniz ürünler listesine eklendi', 'success');
                setFavorite(true)
                addItem('temp.loading', false)
            })
        } else {
            addFavorite(id, true).then(r => {
                notify(product.name + ', beğendiğiniz ürünler listesinden çıkarıldı', 'warning');
                setFavorite(false)
                addItem('temp.loading', false)
            })
        }
    }
    return (
        <>
            {product && product.variants && product.variants.length > 0 && (
                <>
                    <section className="pb-0 breactcrumb-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href={'/'}>Ana Sayfa</Breadcrumb.Item>
                                        { product && product.category.all_parents.length > 0 && product.category.all_parents.map((parent, index) => (
                                            <Breadcrumb.Item href={`/kategori/${slugify(parent.name)}/${parent.id}`}>{parent.name}</Breadcrumb.Item>
                                        ))}
                                        <Breadcrumb.Item href={`/kategori/${slugify(product.category.name)}/${product.category.id}`}>{product.category.name}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="product-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp">
                                    <div className="row g-4">
                                        <div className="col-xl-5 wow fadeInUp">
                                            <div className="product-left-box">
                                                <div className="row g-2">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <ImageWithFallback
                                                                    src={product.image_url}
                                                                    fallbackSrc="assets/images/sahmar-product.png"
                                                                    alt={product.name}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-7 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="right-box-contain card">
                                                <div className="card-header">
                                                    {product.variants && product.variants.length > 0 && product.variants[0].discounted_price > 0 && (
                                                        <h6 className="offer-top">%{calculateDiscountPercentage(product.variants[0].price, product.variants[0].discounted_price)} İndirim</h6>
                                                    )}
                                                    <div className="pb-3">
                                                        <h2 className="name mb-0">{product.name}</h2>
                                                        {product.brand && (
                                                            <Link
                                                                to={`/marka/${slugify(product.brand.name)}/${product.brand.id}`}><b> {product.brand.name}</b></Link>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={`card-body ${active ? 'product-active' : ''}`}>
                                                    <p className="text-muted mb-1">
                                                        <small>{product.variants[0].stock_unit_name}</small></p>
                                                    <div className="price-rating">

                                                        <h3 className="theme-color price mb-3">
                                                            {product.variants && product.variants.length > 0 && product.variants[0].discounted_price > 0 && (
                                                                <>
                                                                    <del
                                                                        className="text-content">{formatCurrency(product.variants[0].price)} TL
                                                                    </del>
                                                                    <br/>
                                                                </>
                                                            )}
                                                            {product.variants && product.variants.length > 0 && product.variants[0].discounted_price > 0 ? (
                                                                <span>{formatCurrency(product.variants[0].discounted_price)} TL</span>
                                                            ) : (
                                                                <span>{formatCurrency(product.variants[0].price)} TL</span>
                                                            )}
                                                        < /h3>
                                                    </div>
                                                    <div className="hr-b"></div>

                                                    <div className="note-box product-packege">
                                                        <div className="position-relative mb-2">
                                                            <AddCart product={product} active={active}
                                                                     setActive={setActive} addItem={addItem}
                                                                     type="button"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-footer">
                                                    <div className="buy-box">
                                                        <button onClick={() => handleFavorite(product.id)}>
                                                            <i className={`fa-${favorite ? 'solid text-danger' : 'regular text-muted'} fa-heart`}></i>
                                                            <span>{favorite ? 'Favorilerimden Çıkar' : 'Favorilerime Ekle'}</span>
                                                        </button>
                                                        <div className="ms-auto share-product">
                                                            <span>Paylaş </span>
                                                            <Link
                                                                to={`${getShare(process.env.REACT_APP_WEBSITE_URL + '/urun/' + product.slug, product.name)}`}
                                                                target="_blank"><i
                                                                className="fa-brands fa-facebook"></i></Link>
                                                            <Link
                                                                to={`${getShare(process.env.REACT_APP_WEBSITE_URL + '/urun/' + product.slug, product.name, 'twitter')}`}
                                                                target="_blank"><i className="fa-brands fa-twitter"></i></Link>
                                                            <Link
                                                                to={`${getShare(process.env.REACT_APP_WEBSITE_URL + '/urun/' + product.slug, product.name, 'linkedin')}`}
                                                                target="_blank"><i
                                                                className="fa-brands fa-linkedin"></i></Link>
                                                            <Link
                                                                to={`${getShare(process.env.REACT_APP_WEBSITE_URL + '/urun/' + product.slug, product.name, 'whatsapp')}`}
                                                                target="_blank"><i
                                                                className="fa-brands fa-whatsapp"></i></Link>
                                                            <Link
                                                                to={`${getShare(process.env.REACT_APP_WEBSITE_URL + '/urun/' + product.slug, product.name, 'telegram')}`}
                                                                target="_blank"><i
                                                                className="fa-brands fa-telegram"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="product-section-box">
                                                <Tabs
                                                    defaultActiveKey="description"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3 custom-nav"
                                                >
                                                    <Tab eventKey="description" title="Ürün Açıklaması" className="p-4">
                                                        <div className="product-contain text-muted mt-2"
                                                             ref={contentRef}>Yükleniyor...
                                                        </div>
                                                    </Tab>
                                                    {/* <Tab eventKey="info" title="Ürün Bilgileri" className="p-4">
                                                        Tab content for Profile
                                                    </Tab>
                                                    <Tab eventKey="revert" title="İade Koşulları" className="p-4">
                                                        Tab content for Profile
                                                    </Tab> */}
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}

        </>
    )
}

export default Detail;
