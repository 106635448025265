import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {handleLogout, user} from "../../../helpers";

const Sidebar = ({define, addItem, active}) => {
    const [orders, setOrders] = useState();

    useEffect(() => {

    }, []);

    return (
        <div className="col-xxl-3 col-lg-4">
            <div className="dashboard-left-sidebar">
                <div className="close-button d-flex d-lg-none">
                    <button className="close-sidebar">
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="profile-box">
                    <div className="cover-image"></div>

                    <div className="profile-contain">
                        <div className="profile-name">
                            <h3>{user().name}</h3>
                            <h6 className="text-content">{user().email}</h6>
                        </div>
                    </div>
                </div>

                <ul className="nav nav-pills user-nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={'/siparisler'} className={`nav-link${active === 'orders' ? ' active' : ''}`}>
                            <i className="fa-duotone fa-bags-shopping me-2 w-22"></i> Siparişler
                        </Link>
                    </li>

                    <li className="nav-item" role="presentation">
                        <Link to={'/begenilenler'} className={`nav-link${active === 'favorites' ? ' active' : ''}`}><i data-feather="heart"></i>
                            <i className="fa-duotone fa-circle-heart me-2 w-22"></i> Beğenilenler
                        </Link>
                    </li>

                    <li className="nav-item" role="presentation">
                        <Link to={'/adresler'} className={`nav-link${active === 'addresses' ? ' active' : ''}`}><i
                            className="fa-duotone fa-map-location-dot me-2 w-22"></i> Adresler
                        </Link>
                    </li>

                    <li className="nav-item" role="presentation">
                        <Link to={'/profil-bilgileri'} className={`nav-link${active === 'profile' ? ' active' : ''}`}><i
                            className="fa-duotone fa-user-unlock me-2 w-22"></i>
                            Profil Bilgileri
                        </Link>
                    </li>

                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-danger" id="pills-security-tab" data-bs-toggle="pill"
                                onClick={() => {
                                    handleLogout()
                                }}><i
                            className="fa-duotone fa-arrow-right-from-bracket me-2 w-22"></i>
                            Çıkış Yap
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
