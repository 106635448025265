import axiosInstance from "../helpers/axios";

export const getFavorites = async (latitude, longitude, limit = 24, offset = 0) => {
    try {
        let url = '/customer/favorites';
        const response = await axiosInstance.get(url + '?latitude=' + latitude + '&longitude=' + longitude + '&limit=' + limit + '&offset=' + offset); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default getFavorites;
