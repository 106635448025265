import React from 'react';

const ImageWithFallback = ({ src, fallbackSrc, alt, width, height, className }) => {
    return (
        <img
            src={src}
            alt={alt}
            width={width  ?? 'auto'}
            height={height  ?? 'auto'}
            className={className}
            onError={(e) => e.target.src = fallbackSrc}
        />
    );
};

export default ImageWithFallback;
