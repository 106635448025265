import axiosInstance from "../helpers/axios";
export const sentOTP = async (phone_number, register = false) => {
    try {
        let url = '/customer/send-otp'; // API'den konumları almak için GET isteği
        let data;
        if(!register){
            data = {mobile: phone_number};
        } else {
            data = {
                register: 1,
                mobile: phone_number,
                name: register.name,
                email: register.email,
                password: register.password
            }
        }
        const response = await axiosInstance.post(url, data); // API'den konumları almak için GET isteği
        return response.data; // İstenen veri buradan dönecektir
    } catch (error) {
        // Hata durumunda burada hata işleme veya yönetimi yapılabilir
        console.error('Error fetching locations:', error);
        throw error; // Hata durumunda çağrıyı yapan yere hatayı iletmek için throw kullanılabilir
    }
};

export default sentOTP;
