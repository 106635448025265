import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Sidebar from "./partials/sidebar";
import getOrders from "../../redux/actions/getOrders";
import {Tab, Tabs, Accordion} from "react-bootstrap";
import {formatCurrency, formatDate, getOrderStatus} from "../../helpers";
import Pagination from "../../components/other/pagination";
import getFavorites from "../../redux/actions/getFavorites";
import ProductGrid from "../../components/boxes/product-grid";

const Favorites = ({define, addItem}) => {
    const [favorites, setFavorites] = useState(10);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        addItem('temp.loading', true);
        getFavorites(define.perm.latitude, define.perm.longitude).then(r => {
            if(r.data){
                setFavorites(r.data);
            }
            addItem('temp.loading', false);
        })
    }, []);
    return (
        <>
            <section className="breadscrumb-section p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Beğenilen Ürünler</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'}>
                                                <i className="fa-solid fa-house"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Beğenilen ürünler</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="user-dashboard-section section-b-space">
                <div className="container">
                    <div className="row">
                        <Sidebar define={define} addItem={addItem} active="favorites"/>
                        <div className="col-lg-9 col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="mb-4">Beğendiğiniz Ürünler</h4>
                                </div>
                                <div className="card-body">
                                    {favorites && favorites?.length > 0 ? (
                                        <div className="row">
                                            {favorites.map((product, index) => (
                                                <div className="col-lg-3 col-md-3 mb-4">
                                                    <ProductGrid product={product} addItem={addItem}/>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center td-bg-white py-4">
                                            <img src="./assets/images/no-cart.png" height={200} alt=""/>
                                            <h5 className="mt-4 mb-4">Hiç beğendiğiniz bir ürün bulunmuyor.</h5>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Favorites;
